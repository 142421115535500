import isNil from 'lodash.isnil';
import {get} from '@ion/core/src/environment/environment';
import {translate} from '@ion/core/src/locale/locale';
import {safeJSONParse} from '@ion/core/src/utils/JSONUtils';
import {isValidDomain} from '@ion/core/src/utils/Validators';
import {containsInvalidDomains} from '../../../helpers';

/**
 * Get the list of Domains from each environment to prevent the user to 
 * create any account with this domain list. 
 */
export const getExcludeddDomainsList = () => {
  return safeJSONParse(get('excludeDomains'));
};

/**
 * Validate the provided domain is exist in the prevented list 
 * from excludedDomains array. 
 */
export const isValidSSODomain = (domains: string): boolean => {
  const excludedDomains = getExcludeddDomainsList();
  const hasInvalidSSODOmain = domains.indexOf(',') > -1
    ? containsInvalidDomains(domains, (item) => excludedDomains[item.trim()])
    : !excludedDomains[domains];
  return hasInvalidSSODOmain;
};

/**
 * Validate the given domain name is valid or not. 
 */
export const hasValidDomains = (domains: string): boolean => {
  const hasInvalidDomain = domains.indexOf(',') > -1
    ? containsInvalidDomains(domains, (item) => !isValidDomain(item.trim()))
    : isValidDomain(domains);
  return hasInvalidDomain;
};

/**
 * Validate the provided domain and return the related warning messages.
 */
export const validateSSODomain = (domain: string): string | null => {
  if (isNil(domain)) {
    return translate('common:editors.shared.required');
  } else if (!hasValidDomains(domain)) {
    return translate('common:editors.shared.invalidDomain');
  } else if (!isValidSSODomain(domain)) {
    return translate('common:editors.shared.isValidSSODomain');
  }
  return null;
};
